define("ember-bootstrap/templates/components/bs-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fvsCHjRC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"element\",\"group\",\"isSubmitting\"],[[27,\"component\",[[23,[\"elementComponent\"]]],[[\"model\",\"formLayout\",\"horizontalLabelGridClass\",\"showAllValidations\",\"onChange\"],[[23,[\"model\"]],[23,[\"formLayout\"]],[23,[\"horizontalLabelGridClass\"]],[23,[\"showAllValidations\"]],[27,\"action\",[[22,0,[]],\"change\"],null]]]],[27,\"component\",[[23,[\"groupComponent\"]]],[[\"formLayout\"],[[23,[\"formLayout\"]]]]],[23,[\"isSubmitting\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form.hbs"
    }
  });

  _exports.default = _default;
});