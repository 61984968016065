define('ember-bootstrap/components/bs-form/element', ['exports', 'ember-bootstrap/components/base/bs-form/element'], function (exports, _element) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _element.default.extend({
    doNotShowValidationForEventTargets: Ember.computed(function () {
      return ['.input-group-append', '.input-group-prepend'];
    })
  });
});